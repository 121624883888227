import React, { Suspense, lazy } from "react";
import { withRouter, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import ContactContainer from "../views/ContactContainer";

import "./BodyContainer.scss";

const Home = lazy(() => import("../views/Home"));
const EventsListView = lazy(() => import("../views/EventsListView"));
const NewsListPage = lazy(() => import("../views/NewsListPage"));
const ShopListPage = lazy(() => import("../views/ShopListPage"));
const Shop = lazy(() => import("../views/ShopPage"));
const EventPage = lazy(() => import("../views/EventPage"));
const NewsPage = lazy(() => import("../views/NewsPage"));
// const PageContainer = lazy(() => import("../views/PageContainer"));

function BodyContainer({ location }) {
  const { key } = location;
  return (
    <TransitionGroup className="bodycontainer">
      <CSSTransition
        key={key}
        timeout={{ enter: 500, exit: 300 }}
        classNames="fade"
      >
        <section className="body">
          <Suspense fallback={<div>Loading...</div>}>
            <Route exact path="/" component={Home} />
            <Route exact path="/evenements" component={EventsListView} />
            <Route exact path="/news" component={NewsListPage} />
            <Route exact path="/shop" component={ShopListPage} />
            <Route exact path="/shop/:id" component={Shop} />
            <Route path="/evenements/:id" component={EventPage} />
            <Route path="/news/:id" component={NewsPage} />
            {/* 
            <Route exact path="/contact" component={ContactContainer} />
            <Route
              exact
              path="/stages"
              render={() => <PageContainer nodeId="9" />}
            />
            <Route
              exact
              path="/visites"
              render={() => <PageContainer nodeId="10" />}
            /> */}
          </Suspense>
        </section>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default withRouter(BodyContainer);
